<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddPorteCards()"
          >Agregar Carta Porte</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de cartas porte: <b>{{ porteCards.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="porteCards"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="PorteCards"
        :checked-rows.sync="checkedPorteCards"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_MARC}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CVE_VEHI"
      >
        <template #empty>
          <div class="has-text-centered">No hay cartas porte</div>
        </template>

        <template slot-scope="props">
          <b-table-column label="Folio" field="NUM_FOLIO" sortable>
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus de timbrado"
            field="STATUS_FAC"
            sortable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FAC)">{{
              "Sin información"
            }}</span>

            <span v-if="props.row.STATUS_FAC === 'SF'">Sin Timbrar</span>
            <span v-if="props.row.STATUS_FAC === 'TI'">Timbrada</span>
            <span v-if="props.row.STATUS_FAC === 'CA'">Cancelada</span>
          </b-table-column>

          <b-table-column label="Fecha de salida" field="formatedDate" sortable>
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.formatedDate)">{{
              props.row.formatedDate
            }}</span>
          </b-table-column>

          <b-table-column
            label="Distancia total"
            field="DISTANCIA_TOTAL"
            sortable
          >
            <span v-if="!checkIfValid(props.row.DISTANCIA_TOTAL)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.DISTANCIA_TOTAL)">{{
              props.row.DISTANCIA_TOTAL
            }}</span>
          </b-table-column>

          <b-table-column label="Peso bruto total" field="BRUTO_TOTAL" sortable>
            <span v-if="!checkIfValid(props.row.BRUTO_TOTAL)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.BRUTO_TOTAL)">{{
              props.row.BRUTO_TOTAL
            }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad de mercancia"
            field="TOTAL_MERCANCIA"
            sortable
          >
            <span v-if="!checkIfValid(props.row.TOTAL_MERCANCIA)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.TOTAL_MERCANCIA)">{{
              props.row.TOTAL_MERCANCIA
            }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div class="controls">
                  <b-button
                    type="is-primary"
                    @click="showAcuseXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'CA'"
                    >Ver acuse de cancelación</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="showPorteCardXML(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Ver XML</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="downloadPorteCardPDF(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Descargar PDF</b-button
                  >

                  <b-button
                    type="is-primary"
                    @click="openSendCFDI(props.row._id)"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    >Enviar CFDI</b-button
                  >

                  <b-button
                    type="is-primary"
                    v-if="props.row.STATUS_FAC === 'TI'"
                    @click="cancelPorteCard(props.row._id)"
                    >Cancelar carta porte</b-button
                  >
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import AddPorteCardNoSale from "./AddPorteCardNoSale.vue";
import moment from "moment/moment";
import PorteCardCancellationModal from "@/modules/Accounting/components/PorteCardCancellationModal.vue";
import XMLViewer from "@/modules/Accounting/components/XMLViewer.vue";
import SendCFDIModal from "@/modules/Accounting/components/SendCFDIModal.vue";

// @ is an alias to /src
export default {
  name: "PorteCardsList",
  components: {},
  data() {
    return {
      perPage: 50,
      checkedPorteCards: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
    };
  },
  async created() {},
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(
        this.$store.dispatch("GETPORTECARDS", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      if (this.$store.getters.PACKAGINGS.length === 0) {
        allPromises.push(this.$store.dispatch("GETPACKAGINGS"));
      }
      if (this.$store.getters.TRANSPORTATIONPERMISSIONS.length === 0) {
        allPromises.push(this.$store.dispatch("GETTRASNPORTATIONPERMISSIONS"));
      }
      allPromises.push(this.$store.dispatch("GETVEHICULES"));
      if (this.$store.getters.TRANSPORTATIONCONFIGURATION.length === 0) {
        allPromises.push(
          this.$store.dispatch("GETTRANSPORTATIONCONFIGURATION")
        );
      }
      if (this.$store.getters.TRAILERTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETTRAILERTYPE"));
      }
      if (this.$store.getters.WEIGHTUNITS.length === 0) {
        allPromises.push(this.$store.dispatch("GETWEIGHTUNITS"));
      }
      if (this.$store.getters.DANGEROUSMATERIALS.length === 0) {
        allPromises.push(this.$store.dispatch("GETDANGEROUSMATERIALS"));
      }
      allPromises.push(this.$store.dispatch("GETARTICLES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETPORTECARDS", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
    openAddPorteCards() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddPorteCardNoSale,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async downloadPorteCardPDF(porteCardId) {
      try {
        let response = await this.$store.dispatch("GETPORTECARDPDF", {
          porteId: porteCardId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al obtener la información del PDF`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el pdf`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async cancelPorteCard(porteId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PorteCardCancellationModal,
        props: {
          porteId: porteId,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async showPorteCardXML(porteCardId) {
      try {
        let response = await this.$store.dispatch("GETPORTECARDXML", {
          porteId: porteCardId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async showAcuseXML(porteId) {
      try {
        let response = await this.$store.dispatch("GETACUSEPORTECARDXML", {
          porteId: porteId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el acuse de la carta porte`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el acuse de la carta porte`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openSendCFDI(porteId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendCFDIModal,
        props: {
          porteId: porteId,
          action: "SENDCFDIPORTECARD",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    porteCards() {
      return this.$store.getters.PORTECARD.map((singlePorteCard) => {
        const singleProteCardWithProps = { ...singlePorteCard };

        singleProteCardWithProps.formatedDate = moment(
          singlePorteCard.FECHA_SALIDA
        ).format("DD-MM-YYYY HH:mm");

        return singleProteCardWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
